
import { execEffect } from "./index";
import { API } from 'aws-amplify';
import { Auth } from "aws-amplify";


export default {
    state: {},
    reducers: {},

    effects: dispatch => ({


        // adddStock adds stock to the terminal database payload is in the form
        // [{"SerialNum":"00000006","TerminalType":"RP10-D1-W"},
        // {"SerialNum":"00000007","TerminalType":"RP10-D1-W"}]

        async addStock(payload) {
            await execEffect(dispatch, async () => {
                const apiName = 'TennantAPI';
                const path = '/managementsite/addstock'
                let myInit = {
                    body: payload,
                    headers: {
                        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                }
                dispatch.alert.silence()
                await API.post(apiName, path, myInit)

            },
                e => dispatch.alert.error(e.message)
            );
        },

        //GET serials 
        async getTerminal() {
            await execEffect(dispatch, async () => {
               
                const apiName = "TennantAPI";
                const path = "managementsite/listterminals";
                const myInit = {
                    headers: {
                        Authorization: `Bearer ${(await Amplify.Auth.currentSession())
                            .getIdToken()
                            .getJwtToken()}`,
                    },
                };
                dispatch.alert.silence()
                await API.get(apiName, path, myInit);
            },
                e => dispatch.alert.error(e.message)
            );
        },

        // checkTerminals Checks stock loading data before it is submitted
        // payload is {data: {SerialNum:"1111",TerminalType:"RP10-D1-W"}, 
        // terminalTypes: [{Name:"RP10-D1-W"},{Name:"RP10-D1-B"}]], - ie. acceptable types
        // error: function to call if there is an error, 
        // display: function to call on success}
        async checkTerminals(payload) {

            //Loading Animation
            await execEffect(dispatch, async () => {
                console.log("payload", payload)
                // Array to hold list of serial numbers to check for duplicates 
                var list = [];


                for (var i = 0; i < payload.data.length; i++) {
                    // Checks the payload and ensures that SerialNum or TerminalType is not Null
                    if (payload.data[i].SerialNum == null || payload.data[i].TerminalType == null) {
                        //Throws relevant error message if above is true
                        //(i + 1).toString()) Tells the user what exactly is null
                        payload.error("SerialNum or TerminalType Missing. Row:" + (i + 1).toString())
                        return;
                    }
                    let found = false;
                    //Checks the terminal types against the stored terminal types
                    //Set let to found if types is correct
                    for (var j = 0; j < payload.terminalTypes.length; j++) {
                        if (payload.data[i].TerminalType === payload.terminalTypes[j].Name) {
                            found = true
                            break;
                        }
                    }
                    //If terminal types is incorrect and not found then display the below
                    //Shows which terminal type and row which is the cause of the error. Line 70
                    if (!found) {
                        payload.error("Invalid Terminal Type. Row:" + (i + 1).toString())
                        return;
                    }

                    //Calls the CheckTerminal API to then determine if the list that was uploaded contains any duplicates
                    console.log("Checkterminal", payload.data[i].SerialNum, i + 1)
                    const apiName = 'TennantAPI';
                    const path = '/managementsite/listterminal/' + payload.data[i].SerialNum;
                    let myInit = {
                        headers: { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` },
                    }
                    //Save the response to the let variable
                    let response = await API.get(apiName, path, myInit)
                    //Checks the response is not null
                    if (response !== null) {
                        payload.error("Terminal Already Exists." + payload.data[i].SerialNum + " Row:" + (i + 1).toString())
                        return
                    }

                    //Checks to see if the List array matches the payload call from the API above
                    if (list.includes(payload.data[i].SerialNum)) {
                        payload.error("Duplicate Terminal." + payload.data[i].SerialNum + " Row:" + (i + 1).toString())
                        return;
                    }
                    list.push(payload.data[i].SerialNum)
                }
                dispatch.alert.silence()
                payload.display()

            },

                e => { console.log("Error", e.response); dispatch.alert.error("Error", payload.serialno) }
            );
        },

        async checkSerials(payload) {

            //Loading Animation
            await execEffect(dispatch, async () => {
                console.log("payload", payload)
                // Array to hold list of serial numbers to check for duplicates 
                var list = [];


                for (var i = 0; i < payload.data.length; i++) {
                    // Checks the payload and ensures that SerialNum or TerminalType is not Null
                    if (payload.data[i].SerialNum == null) {
                        //Throws relevant error message if above is true
                        //(i + 1).toString()) Tells the user what exactly is null
                        payload.error("SerialNum Row:" + (i + 1).toString())
                        return;
                    }

                    //Calls the CheckTerminal API to then determine if the list that was uploaded contains any duplicates
                    console.log("Checkterminal", payload.data[i].SerialNum, i + 1)
                    const apiName = 'TennantAPI';
                    const path = '/managementsite/listterminal/' + payload.data[i].SerialNum;
                    let myInit = {
                        headers: { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` },
                    }
                    //Save the response to the let variable
                    let response = await API.get(apiName, path, myInit)
                    //Checks the response is not null
                    console.log("response", response)
                    if (response.TennantID !== "") {
                        payload.error("This serial already has an assigned TennantID " + payload.data[i].SerialNum + " Row:" + (i + 1).toString())
                        return
                    }

                    //Checks to see if the List array matches the payload call from the API above
                    if (list.includes(payload.data[i].SerialNum)) {
                        payload.error("Duplicate Terminal." + payload.data[i].SerialNum + " Row:" + (i + 1).toString())
                        return;
                    }
                    dispatch.alert.silence()
                    list.push(payload.data[i].SerialNum)
                }
                payload.display()

            },

                e => { console.log("Error", e.response); dispatch.alert.error("Error", payload.serialno) }
            );
        },

        async getOrderDetail() {

            await execEffect(dispatch, async () => {
                dispatch.alert.silence();
                const search = this.props.location.search;
                const params = new URLSearchParams(search);
                const ordernum = params.get('order');
                let apiName = 'TennantAPI';
                let path = 'managementsite/fulfillorder/';
                let myInit = {
                    headers: { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` },
                    queryStringParameters: { start: ordernum, max: 1 }
                }
                console.log('Calling ', apiName)
                console.log("myinit", myInit)
                dispatch.alert.silence()
                return await API.get(apiName, path, myInit);
            },

            );
        },
      

        async RemovePED(payload) {

            await execEffect(dispatch, async () => {
                dispatch.alert.silence();
                let apiName = 'TennantAPI';
                let path = 'managementsite/removefromtid/' + payload.sn;
                let myInit = {
                    headers: { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` },

                }
                console.log('Calling ', apiName, payload.sn)
                dispatch.alert.silence()
                await API.post(apiName, path, myInit).then(() => {dispatch.alert.success(`${payload.sn} has been successfully removed from its TID`)} )
               
            },
                e => { console.log("Error", e.response); dispatch.alert.error("Error whilst removing the PED") }
            );
        },

        async CheckPED(payload) {

            await execEffect(dispatch, async () => {
                let apiName = 'TennantAPI';
                let path = 'manageterminals/checkterminal/' + payload.sn;
                let myInit = {
                    headers: { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` },
                }
                console.log('Calling ', apiName, payload.sn)
                dispatch.alert.silence()
                await API.get(apiName, path, myInit)
            },
                e => { console.log("Error", e.response); dispatch.alert.error("Error whilst checking PED") }
            );
        },

        async logout() {
            await execEffect(dispatch, async () => {
                dispatch.alert.silence()
                await Auth.signOut();
                dispatch.auth.set({});
                dispatch.alert.silence()
            });
        }
    })
};

