import React from "react";
import { MDBContainer, MDBRow, MDBBtn, MDBAnimation } from "mdbreact";
import { withRouter } from "react-router-dom";
import { Formik, Form } from "formik";
import { DefaultTextInput } from "../../components/formik.js";
import InfoHide from "../../components/InfoHide";

const RemovePEDForm = (params) => {
  console.log("params", params);
  return (
    <MDBAnimation type="fadeInLeft">
      <MDBRow style={{ paddingLeft: "0px" }}>
        <Formik
          enableReinitialize
          initialValues={params.ivs}
          onSubmit={(values, { setSubmitting }) => {
            console.log("Submitting", params.history, params.ivs);
            Submit(params, values, { setSubmitting });
          }}
        >
          <Form className="InputForm">
            <MDBRow center style={{ display: "contents" }}>
              <DefaultTextInput
                label="Remove PED From Tennant"
                name="serialNumber"
                type="text"
              />
            </MDBRow>
            <MDBRow center style={{ display: "contents" }}>
              <MDBBtn color="success" type="submit">
                Submit
              </MDBBtn>
            </MDBRow>
          </Form>
        </Formik>
      </MDBRow>
    </MDBAnimation>
  );
};

const Submit = async (params, values, { setSubmitting }) => {
  console.log("Params", params, "values", values);
  let payload = {
    sn: values.serialNumber,
  };
  params.api(payload);

  setSubmitting(false);
};

class RemovePED extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      serialNumber: "",
    };
  }

  render() {
    return (
      <MDBContainer style={{ paddingTop: "50px", minHeight: "73.65vh" }}>
        <MDBRow>
          <InfoHide
            info1="Remove PED"
            info2="Enter the serial no. of the PED that you wish to remove. The serial will be removed from the terminal. Please note that a serial MUST be attached to a terminal before it can be removed."
          />
        </MDBRow>
        <RemovePEDForm
          history={this.props.history}
          ivs={this.state}
          api={this.props.RemovePED}
        />
      </MDBContainer>
    );
  }
}

export default withRouter(RemovePED);
