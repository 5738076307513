import React from "react";
import {useField } from 'formik';
import styled from "@emotion/styled";
import {MDBInput} from "mdbreact";

 export const DefaultTextInput = ({ label, ...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input> and alse replace ErrorMessage entirely.
    const [field, meta] = useField(props);
    return (
          <>
                <StyledLabel htmlFor={props.id || props.name}>{label}</StyledLabel>
                <MDBInput className="text-input" outline {...field} {...props} />
                {meta.touched && meta.error ? (
                      <div className="error">{meta.error}</div>
                ) : null}
          </>
    );
};

export const DefaultCheckbox = ({ children, ...props }) => {
      const [field, meta] = useField({ ...props, type: "checkbox" });
      return (
            <>
                  <StyledLabel className="checkbox">
                        <MDBInput filled type="checkbox" {...field} {...props} />
                        {children}
                  </StyledLabel>
                  {meta.touched && meta.error ? (
                        <div className="error">{meta.error}</div>
                  ) : null}
            </>
      );
};



// Styled components ....
// const StyledSelect = styled.select`
//       color: var(--blue);
//     `;

const StyledErrorMessage = styled.div`
      font-size: 12px;
      color: var(--red-600);
      width: 400px;
      margin-top: 0.25rem;
      &:before {
        content: "❌ ";
        font-size: 10px;
      }
      @media (prefers-color-scheme: dark) {
        color: var(--red-300);
      }
    `;

const StyledLabel = styled.label`
      margin-top: 1rem;
      font-weight: 500;
    `;



export const DefaultSelect = ({ label, ...props }) => {
      // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
      // which we can spread on <input> and alse replace ErrorMessage entirely.
      const [field, meta] = useField(props);
      return (
            <>
                  <StyledLabel htmlFor={props.id || props.name}>{label}</StyledLabel>
                  <select className="browser-default custom-select" {...field} {...props} />
                  {meta.touched && meta.error ? (
                        <StyledErrorMessage>{meta.error}</StyledErrorMessage>
                  ) : null}
            </>
      );
};