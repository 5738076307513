import React from 'react';
import { ConfirmSignIn, ForgotPassword, RequireNewPassword, SignIn, VerifyContact, withAuthenticator, Greetings } from 'aws-amplify-react';
import Alert from "../Alert";
import Routes from '../Routes';
import Loader from "../Loader";
import Dimmer from "../Dimmer";
import PropTypes from "prop-types";
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import { BrowserRouter as Router } from 'react-router-dom';
import {MDBContainer} from 'mdbreact';

const App = ({ loading }) => (
      <div className="flyout">
            <Router>
                  {loading && (
                        <div>
                              <Dimmer />
                              <Loader />
                        </div>
                  )}
                  <Header />
                  <MDBContainer fluid>
                        <Alert />
                        <Routes />
                  </MDBContainer>
                  <Footer />
            </Router>
      </div>
);

App.defaultProps = {
      loading: false
};

App.propTypes = {
      loading: PropTypes.bool
};

// <main style={{ marginTop: '4rem' }}>


// export default withAuthenticator(App, {
//   // Render a sign out button once logged in
//   includeGreetings: true, 
//   // Show only certain components
// });

export default withAuthenticator(App, true, [
      <Greetings />,
      <SignIn />,
      <ConfirmSignIn />,
      <VerifyContact />,
      <ForgotPassword />,
      <RequireNewPassword />
]);