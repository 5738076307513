import React from "react";
import { MDBContainer, MDBRow, MDBCol, MDBAnimation, } from "mdbreact";
import "./Home.css";

class HomePage extends React.Component {
  scrollToTop = () => window.scrollTo(0, 0);
  render() {
    return (
      <MDBContainer fluid style={{ margin: "0!important", padding: "0!important", minHeight: "73.65vh" }}>
          <MDBRow center style={{paddingTop:"7rem"}}>
            <MDBAnimation reveal type="zoomIn">
              <MDBContainer id="section0">
                <MDBRow>
                  <MDBCol md="12" className="mb-4 text-center">
                    <h1 className="logo-scp">
                      <span className="Sure">SURE</span>
                      <span className="Can">CAN</span>
                      <span className="Pay">PAY</span>
                    </h1>
                    <h5 className="text-uppercase pt-md-5 pt-sm-2 pt-5 pb-md-5 pb-sm-3 pb-5 font-weight-bold">
                      Management
                    </h5>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </MDBAnimation>
            </MDBRow>
      
      </MDBContainer>
    );
  }
}

export default HomePage;
