import React from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBtn} from "mdbreact";
import { withRouter } from 'react-router-dom';
// import { LinkContainer } from "react-router-bootstrap";
import Amplify from "../../components/api";
import SCPTable from "../SCPTable.js";
import routes from "../../config/routes";
import InfoHide from "../../components/InfoHide";
import './fulFillOrder.css';

class FulFillOrder extends React.Component {
      constructor(props) {
            super(props);
            this.getOrders = this.getOrders.bind(this);
            this.state = {
                  data: [],
            };
            this.getAllPFOrders = this.getAllPFOrders.bind(this);
            this.getOrders = this.getOrders.bind(this);
      }

      async getOpenOrders() {
            let apiName = 'TennantAPI';
            let path = 'managementsite/fulfillorder';
            let myInit = {
                  headers: { Authorization: `Bearer ${(await Amplify.Auth.currentSession()).getIdToken().getJwtToken()}` },
                  queryStringParameters: { orderStatus: 'Raised' || 'Partially Fulfilled', max:'1000' },
                 
            }
            console.log('Calling ', apiName)
            return await Amplify.API.get(apiName, path, myInit);
      }

      getOrders() {
            this.getOpenOrders().then(response => {
                  console.log('response', response)
                  if (response === null) {
                        this.setState({ data: [] })
                  } else {
                        var array = [], item = [];
                        var i, j;
                        for (i = 0; i < response.length; i++) {

                              for (j = 0; j < response[i].order.length; j++) {
                                    item = [
                                          {text:response[i].OrderNum},
                                          {text:response[i].CreateDate.slice(0, 10)},
                                          {text:response[i].order[j].qty},
                                          {text:response[i].orderStatus},
                                          {text:response[i].orderedBy},
                                          {text:response[i].paymentType},
                                          {text:response[i].deliveryAddress.addressLine1},
                                          {text:response[i].deliveryAddress.postCode},
                                          {text:"FulFill",link:routes.order+'?order='+response[i].OrderNum}
                                    ];
                                    array.push(item);
                              }
                        }
                        this.setState({ data: array })
                  }
            }).catch(error => { console.log('Failed to get data ' + error) })
      }

      async getPFOrders() {
            let apiName = 'TennantAPI';
            let path = 'managementsite/fulfillorder';
            let myInit = {
                  headers: { Authorization: `Bearer ${(await Amplify.Auth.currentSession()).getIdToken().getJwtToken()}` },
                  queryStringParameters: { orderStatus: 'Partially Fulfilled', max:'1000' },
                 
            }
            console.log('Calling ', apiName)
            return await Amplify.API.get(apiName, path, myInit);
      }

      getAllPFOrders() {
            this.getPFOrders().then(response => {
                  console.log('response', response)
                  if (response === null) {
                        this.setState({ data: [] })
                  } else {
                        var array = [], item = [];
                        var i, j;
                        for (i = 0; i < response.length; i++) {

                              for (j = 0; j < response[i].order.length; j++) {
                                    item = [
                                          {text:response[i].OrderNum},
                                          {text:response[i].CreateDate.slice(0, 10)},
                                          {text:response[i].order[j].qty},
                                          {text:response[i].orderStatus},
                                          {text:response[i].orderedBy},
                                          {text:response[i].paymentType},
                                          {text:response[i].deliveryAddress.addressLine1},
                                          {text:response[i].deliveryAddress.postCode},
                                          {text:"FulFill",link:routes.order+'?order='+response[i].OrderNum}
                                    ];
                                    array.push(item);
                              }
                        }
                        this.setState({ data: array })
                  }
            }).catch(error => { console.log('Failed to get data ' + error) })
      }

      componentDidMount() {
            this.getOrders();
      }

      render() {
            const header= ["OrderNum", "CreateDate", "Order.Qty", "OrderStatus","OrderedBy","PaymentType","AddressLine1","Postcode","Action"];
            return (
                  <MDBContainer style={{ paddingTop: "50px", minHeight: "73.65vh" }}>
                      
                              <MDBRow>
                                    <InfoHide 
                                          info1="FulFill Order"
                                          info2="View Orders with the Raised or Partially FulFilled Status"

                                    />
                              <MDBBtn color="amber" className="PFButton" onClick={this.getAllPFOrders}>Partially Fulfilled</MDBBtn>
                              <MDBBtn color="amber" className="RaisedButton" onClick={this.getOrders}>Raised</MDBBtn>
                              </MDBRow>
                      
                        <MDBRow style={{ marginBottom: "10px" }}>
                              <MDBCol style={{paddingLeft:"0px"}}>
                                    <SCPTable header={header} table={this.state.data} />
                              </MDBCol>
                        </MDBRow>
                  </MDBContainer >
            );
      }
}





export default withRouter(FulFillOrder);


