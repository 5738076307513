import React from "react";
import Amplify from "../../components/api";
import SCPTable from "../SCPTable.js";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import InfoHide from "../../components/InfoHide";

class ListOrgs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      org: "",
    };
    this.handleOrg = this.handleOrg.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getOrgList = this.getOrgList.bind(this);
    this.getOrgs = this.getOrgs.bind(this);
  }

  componentDidMount() {
    this.getOrgs();
  }

  // handleSubmit sends an API request and updates state.data with the result
  handleSubmit() {
    this.getOrgs(this.state.org);
  }

  // handleAlpha handles the input box
  handleOrg(event) {
    this.setState({ org: event.target.value });
  }

  async getOrgList() {
    let org = this.state.org;
    let apiName = "TennantAPI";
    let path = "managementsite/listorgs";

    let myInit = {
      headers: {
        Authorization: `Bearer ${(await Amplify.Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
      queryStringParameters: { start: org, max: '1000' }, //Can add Query string here
    };
    console.log("Calling", apiName, path);
    return await Amplify.API.get(apiName, path, myInit); //Make sure you are using correct method get post etc.
  }

  getOrgs() {
    this.getOrgList()
      .then((response) => {
        console.log("response", response);
        if (response === null) {
          this.setState({ date: [] });
        } else {
          let array = [],
            item = [];
          var i;
          for (i = 0; i < response.length; i++) {
            item = [{ text: response[i] }];
            array.push(item);
          }
          this.setState({ data: array });
        }
      })
      .catch((error) => {
        console.log("Failed to get list" + error);
      });
  }

  scrollToTop = () => window.scrollTo(0, 0);

  render() {
    const header = ["Organisation Name"];
    return (
      <MDBContainer style={{ paddingTop: "50px", minHeight: "73.65vh" }}>
  
          <MDBRow>
            <InfoHide
              info1="List Organisations"
              info2="Use the search bar to filter down the results"
            />
          </MDBRow>
          <MDBRow style={{ marginBottom: "10px" }}>
            <MDBCol style={{ paddingLeft: "0px" }}>
              <SCPTable header={header} table={this.state.data} />
            </MDBCol>
          </MDBRow>

      </MDBContainer>
    );
  }
}

export default ListOrgs;
