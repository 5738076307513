import React, { Component } from "react";
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavItem,
  MDBNavLink,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownItem,
  MDBDropdownMenu,
} from "mdbreact";
import { Link } from "react-router-dom";
import routes from "../../config/routes";
import { LinkContainer } from "react-router-bootstrap";
import "./Header.css";

class Header extends Component {
  state = {
    collapseID: "",
  };

  toggleCollapse = (collapseID) => () =>
    this.setState((prevState) => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : "",
    }));

  closeCollapse = (collapseID) => () => {
    window.scrollTo(0, 0);
    this.state.collapseID === collapseID && this.setState({ collapseID: "" });
  };

  render() {
    const overlay = (
      <div
        id="sidenav-overlay"
        style={{ backgroundColor: "transparent" }}
        onClick={this.toggleCollapse("mainNavbarCollapse")}
      />
    );

    const { collapseID } = this.state;

    return (
      <div className="Header">
        <MDBNavbar color="unique-color-dark" dark expand="md" scrolling>
          <MDBNavbarBrand href="/" className="py-0 font-weight-bold">
            <Link
              className="Logo"
              to={routes.home}
              style={{
                listStyle: "none",
                textDecoration: "none",
                color: "white",
              }}
            >
              <h1 className="logo-scp">
                <span className="SureHeader">SURE</span>
                <span className="CanHeader">CAN</span>
                <span className="PayHeader">PAY</span>
                <span className="ManagementHeader">Management</span>
              </h1>
            </Link>
          </MDBNavbarBrand>
          <MDBNavbarToggler
            onClick={this.toggleCollapse("mainNavbarCollapse")}
          />
          <MDBCollapse
            id="mainNavbarCollapse"
            isOpen={this.state.collapseID}
            navbar
          >
            <MDBNavbarNav right>
              <MDBNavItem>
                <MDBNavLink
                  exact
                  to={routes.home}
                  onClick={this.closeCollapse("mainNavbarCollapse")}
                >
                  <strong>Home</strong>
                </MDBNavLink>
              </MDBNavItem>

              <MDBNavItem>
                <MDBDropdown>
                  <MDBDropdownToggle nav caret>
                    <span className="mr-2">
                      <strong>PED</strong>
                    </span>
                  </MDBDropdownToggle>
                  <MDBDropdownMenu>
                    <LinkContainer to={routes.remove}>
                      <MDBDropdownItem>Remove PED</MDBDropdownItem>
                    </LinkContainer>
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavItem>

              <MDBNavItem>
                <MDBDropdown>
                  <MDBDropdownToggle nav caret>
                    <span className="mr-2">
                      <strong>Ordering</strong>
                    </span>
                  </MDBDropdownToggle>
                  <MDBDropdownMenu>
                    <LinkContainer to={routes.stock}>
                      <MDBDropdownItem>Stock Management</MDBDropdownItem>
                    </LinkContainer>
                    <LinkContainer to={routes.fulfill}>
                      <MDBDropdownItem>Fulfill Order</MDBDropdownItem>
                    </LinkContainer>
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavItem>

              <MDBNavItem>
                <MDBNavLink
                  onClick={this.closeCollapse("mainNavbarCollapse")}
                  to={routes.org}
                >
                  <strong>Organisations</strong>
                </MDBNavLink>
              </MDBNavItem>

              <MDBNavItem>
                <MDBNavLink
                  onClick={this.closeCollapse("mainNavbarCollapse")}
                  to={routes.serial}
                >
                  <strong>Serial</strong>
                </MDBNavLink>
              </MDBNavItem>

              {/*<MDBNavItem>*/}
              {/*  <MDBNavLink*/}
              {/*    onClick={this.closeCollapse("mainNavbarCollapse")}*/}
              {/*    to={routes.transactions}*/}
              {/*  >*/}
              {/*    <strong>Transactions</strong>*/}
              {/*  </MDBNavLink>*/}
              {/*</MDBNavItem>*/}

              {/*<MDBNavItem>*/}
              {/*  <MDBNavLink*/}
              {/*    onClick={this.closeCollapse("mainNavbarCollapse")}*/}
              {/*    to={routes.stats}*/}
              {/*  >*/}
              {/*    <strong>Stats</strong>*/}
              {/*  </MDBNavLink>*/}
              {/*</MDBNavItem>*/}

            </MDBNavbarNav>
          </MDBCollapse>
        </MDBNavbar>
        {collapseID && overlay}
      </div>
    );
  }
}

export default Header;
