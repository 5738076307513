import React from "react";
import { LinkContainer } from "react-router-bootstrap";
import { MDBBtn, MDBDataTable } from "mdbreact";


var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'GBP',
});

// SCPTable takes two parameters header and table. header is an array containing the headers.  Array is an array of obects
// the objects are {text:"",link:""}  if link is undefined the text is displayed as text.  If link is defined a button is displayed which when
// pressed goes to the link

const SCPTable = (props) => {

    var row = []
    for (var i = 0; i < props.table.length; i++) {
        var item = {}
        for (var j = 0; j < props.table[i].length; j++) {
            if (props.table[i][j].link !== undefined) {
                item[`${j}`] = <TableButton link={props.table[i][j].link} text={props.table[i][j].text} />
            } else if (props.table[i][j].date !== undefined) {
                item[`${j}`] = props.table[i][j].date.slice(8, 10) + "/" + props.table[i][j].date.slice(5, 7) + "/" + props.table[i][j].date.slice(0, 4)
            } else if (props.table[i][j].datetime !== undefined) {
                item[`${j}`] = props.table[i][j].datetime.slice(4, 6) + "/" + props.table[i][j].datetime.slice(2, 4) + "/" + props.table[i][j].datetime.slice(0, 2) + " " + props.table[i][j].datetime.slice(6, 8) + ":" + props.table[i][j].datetime.slice(8, 10)
            } else if (props.table[i][j].currency !== undefined) {
                item[`${j}`] = formatter.format(props.table[i][j].currency / 100)
            } else {
                item[`${j}`] = props.table[i][j].text
            }
        }
        row.push(item)
    }

    const data = {
        columns: props.header.map(function (array, i) { return { label: array, field: i } }),
        rows: row
    }
    return (
        <MDBDataTable striped bordered small data={data} paging={false} btn={true}  />
    );
}



class TableButton extends React.Component {
    render() {
        return (
            <LinkContainer className="NavItem" to={this.props.link} >
                <MDBBtn color="amber" size="sm" style={{}}>
                    {this.props.text}
                </MDBBtn>
            </LinkContainer>
        );
    }
}

export default SCPTable;   
