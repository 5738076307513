import React from "react";
import { MDBContainer } from "mdbreact";
import Amplify from "../../../components/api";
import { css } from "@emotion/core";
import BarLoader from "react-spinners/BarLoader";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

var formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "GBP",
});

class TransStats extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      dataAA: [],
      dataVP: [],
      loading: false,
      averageDuration: "",
      averageAmount: "",
      transStats: {},
      voids: "",
      complete: "",
      notAuthorised: "",
    };
  }

  componentDidMount() {
    this.getTransactions();
  }

  getLatestTransactions = async (props) => {
    let apiName = "TennantAPI";
    let path = "managementsite/trans/" + this.props.ACQ;
    const myInit = {
      headers: {
        Authorization: `Bearer ${(await Amplify.Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
      queryStringParameters: { max: "300" },
    };
    console.log("Calling ", path);
    return await Amplify.API.get(apiName, path, myInit);
  };

  averageDuration() {
    const averageArray = this.state.data;

    const sum = averageArray
      .map((element) => element.text)
      .reduce((a, b) => a + b, 0);

    const average = sum / averageArray.length;

    this.setState({ averageDuration: average });
  }

  averageAmount() {
    const averageArray = this.state.dataAA;

    const sum = averageArray
      .map((element) => element.currency)
      .reduce((a, b) => a + b, 0);

    const average = sum / averageArray.length;

    const formattedAverage = formatter.format(average / 100);
    console.log("FormattedAverage", formattedAverage);

    this.setState({ averageAmount: formattedAverage });
  }

  voidPercentage() {
    const voidPercent = this.state.dataVP;

    const TransResultStats = voidPercent.reduce((type, amount) => {
      const { Void = 0, Complete = 0, NotAuthorised = 0 } = type;
      if (amount.text === "Void") {
        return { ...type, Void: Void + 1 };
      } else if (amount.text === "Complete") {
        return { ...type, Complete: Complete + 1 };
      } else {
        return { ...type, NotAuthorised: NotAuthorised + 1 };
      }
    }, {});

    this.setState({ transStats: TransResultStats });

    const keyObj = this.state.transStats;

    let dualMidsKeys = Object.keys(keyObj);
    let voidKey = dualMidsKeys.indexOf("Void");
    let completeKey = dualMidsKeys.indexOf("Complete");
    let naKey = dualMidsKeys.indexOf("NotAuthorised");

    let voidValue = Object.values(this.state.transStats)[voidKey];
    this.setState({ voids: voidValue });
    console.log("Void Value", voidValue);
    let voidPercents = voidValue / this.state.dataVP.length;
    console.log("VOID %%%%%%", voidPercents);

    let completeValue = Object.values(this.state.transStats)[completeKey];
    this.setState({ complete: completeValue });
    console.log("Complete Value", completeValue);

    let naValue = Object.values(this.state.transStats)[naKey];
    this.setState({ notAuthorised: naValue });
    console.log("NA Value", naValue);

    console.log("TransStats", TransResultStats); // {A: 3, C: 1, B: 2}
  }

  getTransactions = () => {
    this.showLoader();
    this.getLatestTransactions()
      .then((response) => {
        // console.log("response", response);
        if (response === null) {
          this.setState({ data: [] });
        } else {
          var array = [],
            item = {};
          var i;
          for (i = 0; i < response.length; i++) {
            item = {
              text: response[i].Duration,
            };
            if (item.text !== 0) {
              array.push(item);
            } else {
              console.log("NO ZERO Amounts");
            }
          }
          this.setState({ data: array });
          console.log("KPI DATA", this.state.data);
          this.averageDuration();

          //Average Amount
          var arrayAA = [],
            itemAA = {};
          var x;
          for (x = 0; x < response.length; x++) {
            itemAA = {
              currency: response[x].TxnAmt,
            };
            if (itemAA.currency !== 0 && itemAA.currency < 200000) {
              arrayAA.push(itemAA);
            } else {
              console.log("AMOUNT too HIGh");
            }
          }
          this.setState({ dataAA: arrayAA });
          console.log("KPI DATA", this.state.dataAA);
          this.averageAmount();

          //VoidPercentage
          var arrayVP = [],
            itemVP = {};
          var n;
          for (n = 0; n < response.length; n++) {
            itemVP = {
              text: response[n].TransResult,
            };
            arrayVP.push(itemVP);
          }
          this.setState({ dataVP: arrayVP });
          console.log("KPI DATA", this.state.dataVP);
          this.voidPercentage();

          this.hideLoader();
        }
      })
      .catch((error) => {
        console.log("Failed to get data " + error);
        this.hideLoader();
      });
  };

  hideLoader = () => {
    this.setState({ loading: false });
  };

  showLoader = () => {
    this.setState({ loading: true });
  };

  render() {
    return (
      <>
        <MDBContainer>
          <h3>Acquirer : {this.props.ACQ}</h3>
          <br />
          <p>
            Average Transaction Duration : {this.state.averageDuration}ms{" "}
            {this.state.loading ? (
              <div className="sweet-loading">
                <BarLoader
                  css={override}
                  size={150}
                  color={"#123abc"}
                  loading={this.state.loading}
                />
              </div>
            ) : null}
          </p>
          <p>
            Average Amount : {this.state.averageAmount}{" "}
            {this.state.loading ? (
              <div className="sweet-loading">
                <BarLoader
                  css={override}
                  size={150}
                  color={"#123abc"}
                  loading={this.state.loading}
                />
              </div>
            ) : null}
          </p>
          <p>Transaction Stats :</p>
          <p style={{ marginLeft: "7px" }}>
            Voids: {this.state.voids}{" "}
            {this.state.loading ? (
              <div className="sweet-loading">
                <BarLoader
                  css={override}
                  size={150}
                  color={"#123abc"}
                  loading={this.state.loading}
                />
              </div>
            ) : null}
          </p>
          <p style={{ marginLeft: "7px" }}>
            Complete: {this.state.complete}{" "}
            {this.state.loading ? (
              <div className="sweet-loading">
                <BarLoader
                  css={override}
                  size={150}
                  color={"#123abc"}
                  loading={this.state.loading}
                />
              </div>
            ) : null}
          </p>
          <p style={{ marginLeft: "7px" }}>
            Not Authorised: {this.state.notAuthorised}
            {this.state.loading ? (
              <div className="sweet-loading">
                <BarLoader
                  css={override}
                  size={150}
                  color={"#123abc"}
                  loading={this.state.loading}
                />
              </div>
            ) : null}
          </p>
        </MDBContainer>
      </>
    );
  }
}

export default TransStats;
