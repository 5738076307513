import React from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBIcon,
  MDBAnimation,
  MDBInput,
} from "mdbreact";
import routes from "../../config/routes";
import { LinkContainer } from "react-router-bootstrap";
import { withRouter } from "react-router-dom";
import Amplify from "../../components/api";
import SCPTable from "../SCPTable.js";
import "./fulFillOrder.css";

class CompleteOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      qtyData: [],
      PFdata: [],
      serials: [],
      orderNumber: [],
      SerialNumber: {},
      completed: false,
      enteredSerial: false,
      error: "",
    };
    this.getOrder = this.getOrder.bind(this);
    this.handleError = this.handleError.bind(this);
    this.setError = this.setError.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getFullFilledDetails = this.getFullFilledDetails.bind(this);
    this.getQtyOrder = this.getQtyOrder.bind(this);
  }

  //Sets the state of error
  handleError = () => {
    this.state.error("");
  };

  setError = (msg) => {
    this.setState({ error: msg });
  };

  async getOrderDetail() {
    const search = this.props.location.search;
    const params = new URLSearchParams(search);
    const ordernum = params.get("order");
    let apiName = "TennantAPI";
    let path = "managementsite/fulfillorder/";
    let myInit = {
      headers: {
        Authorization: `Bearer ${(await Amplify.Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
      queryStringParameters: { start: ordernum, max: 1 },
    };
    console.log("Calling ", apiName);
    console.log("myinit", myInit);
    return await Amplify.API.get(apiName, path, myInit);
  }

  getOrder() {
    this.getOrderDetail()
      .then((response) => {
        console.log("response", response);
        if (response === null) {
          this.setState({ data: [] });
        } else {
          var array = [],
            item = [];
          var i, j;
          for (i = 0; i < response.length; i++) {
            for (j = 0; j < response[i].orderDetail.length; j++) {
              item = [
                { text: response[i].orderDetail[j].TID },
                { text: response[i].orderDetail[j].terminalType },
                { text: response[i].orderDetail[j].status },
                {
                  text: "Serial",
                  label: "Enter Serial",
                  value: this.state.serials,
                },
              ];
              array.push(item);
            }
          }
          this.setState({ data: array });
        }
      })
      .catch((error) => {
        console.log("Failed to get data " + error);
      });
  }

  getQtyOrder() {
    this.getOrderDetail()
      .then((response) => {
        console.log("response", response);
        if (response === null) {
          this.setState({ qtyData: [] });
        } else {
          var array = [],
            item = [];
          var i, j;
          for (i = 0; i < response.length; i++) {
            for (j = 0; j < response[i].order.length; j++) {
              item = [
                { text: response[i].order[j].qty },
              ];
              array.push(item);
            }
          }
          this.setState({ qtyData: array });
          this.getOrder();
          this.getFullFilledDetails();
        }
      })
      .catch((error) => {
        console.log("Failed to get data " + error);
      });
  }

  getFullFilledDetails() {
    this.getOrderDetail()
      .then((response) => {
        console.log("PFresponse", response);
        if (response === null) {
          this.setState({ PFdata: [] });
        } else {
          var array = [],
            item = [];
          var i, j;
          for (i = 0; i < response.length; i++) {
            for (j = 0; j < response[i].dispatched.length; j++) {
              item = [
                { text: response[i].dispatched[j].dispatchDate },
                { text: response[i].dispatched[j].qty },
                { text: response[i].dispatched[j].terminalType },
              ];
              array.push(item);
            }
          }
          this.setState({ PFdata: array });
        }
      })
      .catch((error) => {
        console.log("Failed to get data " + error);
      });
  }

  async postOrder(serialArray) {
    const search = this.props.location.search;
    const params = new URLSearchParams(search);
    const ordernum = params.get("order");
    let apiName = "TennantAPI";
    let path = "managementsite/fulfillorder/" + ordernum;
    let myInit = {
      headers: {
        Authorization: `Bearer ${(await Amplify.Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
      body: serialArray,
    };
    console.log("Calling ", apiName, serialArray, ordernum);
    return await Amplify.API.post(apiName, path, myInit)
      .then(() => {
        console.log("refresh");
        this.addedSerials();
      })
      .catch((error) => {
        console.log("Failed to get Terminal Types " + error);
      });
  }

  addedSerials() {
    this.setState({ enteredSerials: false });
    this.setState({ completed: true });
    console.log("Added Serials!");
  }

  componentDidMount() {
    this.getQtyOrder();
  }
  

  addSerial() {
    this.setState({ completed: false });
    this.setState({ serials: [...this.state.serials, ""] });
  }

  handleChange(e, index) {
    // eslint-disable-next-line
    this.state.serials[index] = e.target.value;
    this.setState({ serials: this.state.serials });
    console.log("Serial", this.state.serials);

    if (this.state.serials.length < 1) {
      this.setState({ enteredSerials: false });
    } else {
      this.setState({ enteredSerial: true });
    }
  }

  handleRemove(index) {
    this.state.serials.splice(index, 1);
    this.setState({ serials: this.state.serials });
  }

  handleSubmit = () => {
    let serialArray = [];
    this.setState({ error: "" });
    for (var i = 0; i < this.state.serials.length; i++) {
      if (this.state.serials[i].length > 13) {
        this.setState({ error: "Serial too long " + this.state.serials[i] });
      } else if (this.state.serials[i].length <= 1) {
        this.setState({
          error: "Serial is too short " + this.state.serials[i],
        });
      } else {
        serialArray.push({ SerialNum: this.state.serials[i] });
        this.postOrder(serialArray);
      }
    }
  };

  render() {
    const header = ["Terminal ID", "Terminal Type", "Status"];

    return (
      <MDBContainer style={{ paddingTop: "50px", minHeight: "73.65vh" }}>
        <MDBAnimation type="fadeInLeft">
          <p>{this.state.error}</p>
          <MDBRow style={{ paddingLeft: "0px" }}>
            <MDBCol style={{ paddingLeft: "0px!important" }}>
              {this.state.serials.map((serial, index) => {
                return (
                  <div key={index} className="AddSerial">
                    <MDBInput
                      type="text"
                      label="Enter Serial"
                      background
                      size="sm"
                      onChange={(e) => this.handleChange(e, index)}
                      value={serial}
                    />
                    <MDBBtn
                      color="red"
                      size="sm"
                      onClick={() => this.handleRemove(index)}
                    >
                      Remove
                    </MDBBtn>
                  </div>
                );
              })}
              <MDBBtn
                type="submit"
                size="sm"
                onClick={(e) => this.addSerial(e)}
              >
                Add Serial
              </MDBBtn>
            </MDBCol>
          </MDBRow>
          {console.log("PFData", this.state.PFdata)}
          {console.log("QTYData", this.state.qtyData)}
          {this.state.PFdata.length !== 0 ? (
            <MDBCol>
              <p>
                PEDs already dispatched:{" "}
                <strong>{this.state.PFdata[0][1].text}</strong>{" "}
              </p>
              <p>
                Date Dispatched: <strong>{this.state.PFdata[0][0].text}</strong>{" "}
              </p>
              <p>
                Type of PED Dispatches:{" "}
                <strong>{this.state.PFdata[0][2].text}</strong>{" "}
              </p>
              <p>
                Remaining PEDs to FulFill:
                <strong style={{color:"red"}}>{parseInt(parseInt(10, this.state.qtyData[0][0].text) - this.state.PFdata[0][1].text)}</strong>
              </p>
            </MDBCol>
          ) : (
            <p></p>
          )}
          <MDBRow style={{ marginBottom: "10px" }}>
            <MDBCol>
              <SCPTable header={header} table={this.state.data} />
              <LinkContainer className="NavItem" to={routes.fulfill}>
                <MDBBtn color="amber" size="sm" style={{}}>
                  <MDBIcon icon="angle-left" /> Back
                </MDBBtn>
              </LinkContainer>
              {this.state.enteredSerial ? (
                <MDBBtn
                  id="submit"
                  size="sm"
                  type="submit"
                  color="success"
                  onClick={(e) => this.handleSubmit(e)}
                  style={{ float: "right" }}
                >
                  Submit
                </MDBBtn>
              ) : (
                <MDBBtn
                  id="submit"
                  size="sm"
                  type="submit"
                  color="success"
                  disabled
                  style={{ float: "right" }}
                >
                  Submit
                </MDBBtn>
              )}
            </MDBCol>
          </MDBRow>
          {this.state.completed ? (
            <div>
              <h4 style={{ color: "green" }}>
                <MDBIcon icon="check" /> Serial(s) added successfully
              </h4>
            </div>
          ) : (
            <MDBAnimation type="bounceOut" delay="5s">
              <div>
                <p>Add Serials and click submit</p>
              </div>
              {/* {console.log("NotSubmitted", this.state.isSubmitted)} */}
            </MDBAnimation>
          )}
        </MDBAnimation>
      </MDBContainer>
    );
  }
}

export default withRouter(CompleteOrder);