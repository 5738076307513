import React, { useEffect, useState } from "react";
import SCPTable from "../SCPTable.js";
import Amplify from "../../components/api";
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import InfoHide from "../../components/InfoHide";
import "./SerialPage.css";

const header = [
  "SerialNum",
  "Tennant ID",
  "Create Date",
  "TerminalType",
  "PIN Date",
  "Update Date",
  "Firmware Version"
];

const getTerminal = async () => {
  let apiName = "TennantAPI";
  let path = "managementsite/listterminals";

  let myInit = {
    headers: {
      Authorization: `Bearer ${(await Amplify.Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    },
    queryStringParameters: { max: "10000" },
  };
  return await Amplify.API.get(apiName, path, myInit); //Make sure you are using correct method get post etc.
}

const SerialPage = () => {
  let [data, setData] = useState([]);
  let [loading, setLoading] = useState(false);
  // let [loader, showLoader]

  useEffect(() => {
    getTerminal()
      .then((response) => {
        console.log("response", response);
        if (response === null) {
          // this.setState({ data: [] });
          setData(data = response)
          console.log("Data", data);
        } else {
          setLoading(loading = true)
          let array = [],
            item = [];
          var i;
          for (i = 0; i < response.length; i++) {
            item = [
              { text: response[i].SerialNum },
              { text: response[i].TennantID },
              { date: response[i].CreateDate },
              { text: response[i].TerminalType },
              { date: response[i].PINDate },
              { date: response[i].UpdateDate },
              { text: response[i].FirmwareVersion },
            ];
            array.push(item);
          }
          // this.setState({ data: array });
          setData(data = array)
        }
      })
      .catch((error) => {
        console.log("Failed to get terminal" + error);
      });
    setLoading(loading = false)
  }, [])

  const Loader = () => {
    if (loading !== true) {
      return (<div className="loader">
        <MDBIcon icon="circle-notch" spin size="3x" fixed />
        <span>Loading ...
        </span>
        </div>)
    } else {
      return <p></p>
    }

  }

  return (

    <MDBContainer style={{ paddingTop: "50px", minHeight: "73.65vh" }}>
      <div>
      <Loader />
        <MDBRow>
          <InfoHide
            info1="Serial Search"
            info2="Use the search bar to filter down the results"
          />
        </MDBRow>
       

        <MDBRow style={{ marginBottom: "10px" }}>
          <MDBCol style={{ paddingLeft: "0px" }}>
            <SCPTable header={header} table={data} />
          </MDBCol>
        </MDBRow>
      </div>
    </MDBContainer>

  )
}

export default SerialPage;
