export default {
      state: false,
      reducers: {
            start() {
                  return true;
            },
            stop() {
                  return false;
            }
      }
};
