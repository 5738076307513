import { MDBCol, MDBCard, MDBCardBody,} from "mdbreact";
import { Component } from "react";
import React from "react";
import './InfoBlock.css';

class InfoBlock extends Component {

    render() {
        return (

            <MDBCol md="3"style={{paddingLeft:"0px"}}>
                <MDBCard border="primary" className="" style={{marginLeft:"0!important", marginBottom:"10px"}}>
                    <MDBCardBody className="text-primary">
                        <code>
                            {this.props.CardText2}
                        </code>
                        <br />
                        <code>
                            {this.props.CardText3}
                        </code>
                        <br />
                        <code>
                            {this.props.CardText4}
                        </code>
                    </MDBCardBody>
                </MDBCard>
            </MDBCol>
        )
    }
}

export default InfoBlock;