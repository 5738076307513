import React, { Component } from 'react';
import { MDBFooter } from 'mdbreact';

class Footer extends Component {
    render() {
        return (
            <MDBFooter color="unique-color-dark">
                <p className='footer-copyright mb-0 py-3 text-center'>
                    &copy; {new Date().getFullYear()} Copyright:
    <a href='https://www.SureCanPay.com'> SureCanPay.com </a>
                </p>
            </MDBFooter>
        )
    }
}

export default Footer;