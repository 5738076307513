import React from "react";
import Amplify from "../../components/api";
import SCPTable from "../SCPTablePages.js";
import {
  MDBContainer,
  MDBRow,
  MDBCardHeader,
  MDBCard,
  MDBCardBody,
  MDBBtn,
  MDBIcon,
} from "mdbreact";
import InfoHide from "../../components/InfoHide";
import "./Transactions.css";
import { css } from "@emotion/core";
import BarLoader from "react-spinners/BarLoader";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

class Transactions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      Acquirers: "",
      Acquirer: "bms",
      acqText: "bms",
      dev: false,
      loading: false,
    };
    this.getAmexTrans = this.getAmexTrans.bind(this);
    this.getAmexDevTrans = this.getAmexDevTrans.bind(this);
    this.getTransactions = this.getTransactions.bind(this);
    this.getBmsTrans = this.getBmsTrans.bind(this);
    this.getTestTrans = this.getTestTrans.bind(this);
  }

  componentDidMount() {
    this.getAcq();
    this.intervalID = setInterval(this.getTransactions.bind(this), 500000);
    this.setState({ loading: false });
  }

  componentWillUnmount() {
    /*
          stop getData() from continuing to run even
          after unmounting this component
        */
    clearInterval(this.intervalID);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.Acquirer !== this.state.Acquirer) {
      console.log("Acq state has changed.");
    }
  }

  getBmsTrans() {
    this.setState({ Acquirer: "bms" }, () => {
      this.getTransactions();
    });
    this.setState({ acqText: "bms" });
  }

  getTestTrans() {
    this.setState({ Acquirer: "test" }, () => {
      this.getTransactions();
    });
    this.setState({ acqText: "test" });
  }

  getAmexDevTrans() {
    this.setState({ Acquirer: "amexdev" }, () => {
      this.getTransactions();
    });
    this.setState({ acqText: "amexdev" });
  }

  getAmexTrans() {
    this.setState({ Acquirer: "amex" }, () => {
      this.getTransactions();
    });
    this.setState({ acqText: "amex" });
  }

  sortAcquirers() {
    //Gets Acquirers from API
    const AcqArray = this.state.Acquirers;
    let bmsTestIndex = AcqArray.indexOf("test");

    if (bmsTestIndex !== -1) {
      this.setState({ dev: true });
      console.log("DEV TRUE");
    }

    this.setState({ Acquirers: AcqArray });
    //console.log("ACQ State", this.state.Acquirers);
  }

  getLatestTransactions = async () => {
    let apiName = "TennantAPI";
    let path = "managementsite/trans/" + this.state.Acquirer;
    const myInit = {
      headers: {
        Authorization: `Bearer ${(await Amplify.Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
      queryStringParameters: { max: "300" },
    };
    console.log("Calling ", path);
    return await Amplify.API.get(apiName, path, myInit);
  };

  getAcquirers = async () => {
    let apiName = "TennantAPI";
    let path = "managementsite/listacquirers";
    let myInit = {
      headers: {
        Authorization: `Bearer ${(await Amplify.Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };
    console.log("Calling ", path, "Acquirers");
    return await Amplify.API.get(apiName, path, myInit);
  };

  getAcq = () => {
    this.getAcquirers()
      .then((response) => {
        console.log("Acquirer Response", response);
        if (response.length > 0) {
          this.setState({ Acquirers: response });
          this.sortAcquirers();
        }
        //If the Acq does not contain bms (live) it will then load Test transactions first
        if (this.state.dev === false) {
          this.getTransactions();
        } else {
          this.getTestTrans();
        }
      })
      .catch((error) => {
        console.log("Failed to get Acquirer data " + error);
      });
  };

  getTransactions = () => {
    if (this.state.Acquirers.length > 0) {
      this.showLoader();
      this.getLatestTransactions()
        .then((response) => {
          console.log("response", response);
          if (response === null) {
            this.setState({ data: [] });
          } else {
            var array = [],
              item = [];
            var i;
            for (i = 0; i < response.length; i++) {
              item = [
                { text: response[i].TransType },
                { text: response[i].Mid },
                { text: response[i].Tid },
                { text: response[i].SerialNum },
                { text: response[i].TennantID },
                { text: response[i].BatchNum },
                { text: response[i].TransNum },
                { text: response[i].EPOSTxnNum },
                { currency: response[i].TxnAmt },
                { date: response[i].TransTime },
                { dateTime: response[i].TransTime },
                { text: response[i].MaskedPAN },
                { text: response[i].ResponseCode },
                { text: response[i].TransResult },
                { text: response[i].Duration },
              ];
              // console.log("ITEM", item)
              array.push(item);
            }
            this.setState({ data: array });
            this.hideLoader();
          }
        })
        .catch((error) => {
          console.log("Failed to get data " + error);
          this.hideLoader();
        });
    }
  };

  hideLoader = () => {
    this.setState({ loading: false });
  };

  showLoader = () => {
    this.setState({ loading: true });
  };

  render() {
    const header = [
      "TransType",
      "Mid",
      "Tid",
      "Serial",
      "Tenant",
      "Batch",
      "Number",
      "EPOSTxn",
      "Amount",
      "Date",
      "Time",
      "Masked PAN",
      "Response Code",
      "Trans Result",
      "Duration",
    ];

    return (
      <MDBContainer fluid style={{ paddingTop: "50px", minHeight: "73.65vh" }}>
        <MDBRow middle>
          <InfoHide
            info1="Transaction Page:"
            info2="Use the search bar to filter down the results. Each column has a sort function. Use the buttons to the right to filter by each Acquirer."
          />
        </MDBRow>

        <MDBRow middle>
          <MDBCard narrow>
            <MDBCardHeader className="view view-cascade gradient-card-header blue-gradient d-flex justify-content-between align-items-center py-2 mx-4 mb-3">
              <div></div>
              <p className="white-text mx-3">
                Transactions (Latest 300) - Acquirer: {this.state.acqText}
              </p>
              <div>
                {this.state.dev === false ? (
                  <MDBBtn
                    outline
                    rounded
                    size="sm"
                    color="white"
                    className="px-2"
                    onClick={this.getBmsTrans}
                  >
                    BMS
                    <MDBIcon far icon="credit-card" />
                  </MDBBtn>
                ) : (
                    <MDBBtn
                      outline
                      rounded
                      size="sm"
                      color="white"
                      className="px-2"
                      onClick={this.getTestTrans}
                    >
                      Test
                      <MDBIcon far icon="credit-card" />
                    </MDBBtn>
                  )}
                {this.state.dev === false ? (
                  <MDBBtn
                    outline
                    rounded
                    size="sm"
                    color="white"
                    className="px-2"
                    onClick={this.getAmexTrans}
                  >
                    Amex
                    <MDBIcon icon="credit-card" />
                  </MDBBtn>
                ) : (
                    <MDBBtn
                      outline
                      rounded
                      size="sm"
                      color="white"
                      className="px-2"
                      onClick={this.getAmexDevTrans}
                    >
                      AmexDev
                      <MDBIcon far icon="credit-card" />
                    </MDBBtn>
                  )}
              </div>
            </MDBCardHeader>
            <MDBCardBody cascade>
              {this.state.loading ? (
                <div className="sweet-loading">
                  <BarLoader
                    css={override}
                    height={6}
                    width={400}
                    color={"#123abc"}
                    loading={this.state.loading}
                  />
                </div>
              ) : null}
              <SCPTable header={header} table={this.state.data}></SCPTable>
            </MDBCardBody>
          </MDBCard>
        </MDBRow>
      </MDBContainer>
    );
  }
}

export default Transactions;
