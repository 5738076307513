import React from "react";
import { Route, Switch } from "react-router-dom";
import HomePage from "../../pages/Home/HomePage";
import SerialPage from "../../pages/Serial/SerialPage";
import StockManagementPage from "../../pages/StockManagement";
import FulFillOrder from "../../pages/fulFillOrder/FulFillOrder.js";
import ListOrg from "../../pages/ListOrg/ListOrg";
import CompleteOrder from "../../pages/fulFillOrder/CompleteOrder";//Needs removing once Complete Order API work is finished
import RemovePED from "../../pages/RemovePED";
import Transactions from "../../pages/Transactions";
import Stats from "../../pages/Stats";
import routes from "../../config/routes";
import Orders from "../../pages/Orders";

class Routes extends React.Component {
  render() {
    return (
      <Switch>
        <Route exact path={routes.home} component={HomePage} />
        <Route exact path={routes.serial} component={SerialPage} />
        <Route exact path={routes.org} component={ListOrg} />
        <Route exact path={routes.stock} component={StockManagementPage} />
        <Route exact path={routes.fulfill} component={FulFillOrder} />
        <Route exact path={routes.order} component={Orders} />
        <Route exact path={routes.remove} component={RemovePED} />
        <Route exact path={routes.complete} component={CompleteOrder} />
        <Route exact path={routes.transactions} component={Transactions} />
        <Route exact path={routes.stats} component={Stats} />
        <Route
          render={function () {
            return <h1>Not Found</h1>;
          }}
        />
      </Switch>
    );
  }
}

export default Routes;
