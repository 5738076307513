import React from "react";
// import Amplify from "../../components/api";
import {
  MDBContainer,
  MDBTabPane,
  MDBTabContent,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBRow,
  MDBIcon,
} from "mdbreact";
// import AverageDuration from "./KPIBar/AverageDuration";
// import AverageAmount from "./KPIBar/AverageAmount";
// import VoidPercentage from "./KPIBar/VoidPercentage";
import TransStats from "./KPIBar/TransSats";

class StatsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      activeItemOuterTabs: "1",
      activeItemInnerPills: "1",
    };
  }

  toggleOuterTabs = (tab) => (e) => {
    if (this.state.activeItemOuterTabs2 !== tab) {
      this.setState({
        activeItemOuterTabs: tab,
      });
    }
  };

  toggleInnerPills = (tab) => (e) => {
    if (this.state.activeItemInnerPills !== tab) {
      this.setState({
        activeItemInnerPills: tab,
      });
    }
  };

  componentDidMount() {}

  scrollToTop = () => window.scrollTo(0, 0);

  render() {
    return (
      <>
        <MDBContainer style={{ paddingTop: "50px", minHeight: "73.65vh" }}>
          <MDBContainer>
            <h2 className="mt-5">Transaction Stats (Last 300 Transactions)</h2>
            <MDBNav tabs className="nav-justified" color="primary">
              <MDBNavItem>
                <MDBNavLink
                  link
                  to="#"
                  active={this.state.activeItemOuterTabs === "1"}
                  onClick={this.toggleOuterTabs("1")}
                  role="tab"
                >
                  <MDBIcon far icon="credit-card" /> BMS
                </MDBNavLink>
              </MDBNavItem>

              <MDBNavItem>
                <MDBNavLink
                  link
                  to="#"
                  active={this.state.activeItemOuterTabs === "2"}
                  onClick={this.toggleOuterTabs("2")}
                  role="tab"
                >
                  <MDBIcon far icon="credit-card" /> Amex
                </MDBNavLink>
              </MDBNavItem>

              <MDBNavItem>
                <MDBNavLink
                  link
                  to="#"
                  active={this.state.activeItemOuterTabs === "3"}
                  onClick={this.toggleOuterTabs("3")}
                  role="tab"
                >
                  <MDBIcon far icon="credit-card" /> Test
                </MDBNavLink>
              </MDBNavItem>

              <MDBNavItem>
                <MDBNavLink
                  link
                  to="#"
                  active={this.state.activeItemOuterTabs === "4"}
                  onClick={this.toggleOuterTabs("4")}
                  role="tab"
                >
                  <MDBIcon far icon="credit-card" /> AmexDev
                </MDBNavLink>
              </MDBNavItem>
            </MDBNav>
            <MDBTabContent
              className="card mb-5"
              activeItem={this.state.activeItemOuterTabs}
            >
              <MDBTabPane tabId="1" role="tabpanel">
                <TransStats ACQ="bms" />
              </MDBTabPane>

              <MDBTabPane tabId="2" role="tabpanel">
                <TransStats ACQ="amex" />
              </MDBTabPane>

              <MDBTabPane tabId="3" role="tabpanel">
                <TransStats ACQ="test" />
              </MDBTabPane>

              <MDBTabPane tabId="4" role="tabpanel">
                <TransStats ACQ="amexdev" />
              </MDBTabPane>
            </MDBTabContent>
          </MDBContainer>

          <MDBRow></MDBRow>
        </MDBContainer>
      </>
    );
  }
}

export default StatsPage;
