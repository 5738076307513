import "./Alert.css";
import React from "react";
import PropTypes from "prop-types";
import { MDBAlert } from "mdbreact";


const Component = ({ alert: { message, color } }) => (
      <div className="Alert">
            {message && (
                  <>
                  <p></p>
                  <MDBAlert color="primary" color={color} >
                        {message}
                  </MDBAlert>
                  </>
            )}
      </div>
);

Component.propTypes = {
      alert: PropTypes.shape({
            type: PropTypes.string,
            message: PropTypes.string
      }).isRequired,
      silence: PropTypes.func.isRequired
};

export default Component;
