// StockManagement loads a CSV file containing stock to load in the following format

// SerialNum,TerminalType
// "12345678","RP10-D1-W"
// "77777777","RP10-D1-W"

// If the header is wrong or any of the rows contain terminal types that don't exist or terminal ids that have already been loaded the file is rejected and the
// errors shown
// If the file is OK - Its contents are show and the user is asked to submit.  The contents are then loaded onto the database

import React from "react";
import {
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBContainer,
  MDBAnimation,
  MDBIcon,
  MDBTable, 
  MDBTableBody,
  MDBTableHead
} from "mdbreact";
import CSVReader from "react-csv-reader";
import Amplify from "../../api.js";
import SCPTable from "../SCPTable.js";
import InfoHide from "../../components/InfoHide";
import "./StockManagement.css";

const papaparseOptions = {
  header: true,
  skipEmptyLines: true,
};
class StockManagementPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileLoaded: false,
      error: "",
      terminalTypes: [],
      table: [],
      isSubmitted: false,
    };
    this.fileLoaded = this.fileLoaded.bind(this);
    this.handleError = this.handleError.bind(this);
    this.setError = this.setError.bind(this);
    this.displayData = this.displayData.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.isSubmitted = this.isSubmitted.bind(this);
  }

  //Sets the state of error
  handleError = () => {
    this.state.error("Invalid File format");
  };

  handleSubmit = () => {
    let payload = [];
    //Goes through each row of the table and pushes the payload.
    for (var i = 0; i < this.state.table.length; i++) {
      payload.push({
        SerialNum: this.state.table[i][0].text,
        TerminalType: this.state.table[i][1].text,
      });
    }
    this.props.addStock(payload);
    this.setState({ isSubmitted: true });
    //Goes back to previous page
    this.setState({ fileLoaded: false });
    // this.setState({isSubmitted: false})
  };

  //Error Message State
  setError = (msg) => {
    this.setState({ error: msg });
  };

  isSubmitted() { }

  fileLoaded = (data) => {
    //Setting state of errors
    this.setState({ error: "" });
    //Check Terminals before file uploaded
    this.props.checkTerminals({
      data: data,
      terminalTypes: this.state.terminalTypes,
      error: this.setError,
      display: this.displayData,
    });

    //Goes through the table array
    let table = [];
    for (var i = 0; i < data.length; i++) {
      table.push([{ text: data[i].SerialNum }, { text: data[i].TerminalType }]);
    }
    this.setState({ table: table });
    this.setState({ isSubmitted: false });
  };

  //Sets state of file loaded to true
  displayData = () => {
    this.setState({ fileLoaded: true });
  };

  // On page load get the valid terminal types and store in state.terminalTypes
  componentDidMount = () => {
    this.getTerminalTypes();
  };

  async getTerminalTypesAPI() {
    const apiName = "TennantAPI";
    const path = "/terminaltypes";
    return await Amplify.API.get(apiName, path);
  }

  //Calls the gtt API which check for valid terminal types and stores in a state terminalTypes
  getTerminalTypes = () => {
    this.getTerminalTypesAPI()
      .then((response) => {
        this.setState({
          terminalTypes: response,
        });
        console.log(this.state.terminalTypes);
      })
      .catch((error) => {
        console.log("Failed to get Terminal Types " + error);
      });
  };

  render() {
    // Render of valid table headers.
    const header = ["Serial Number", "Terminal Type"];

    return (
      <MDBContainer>
        <MDBRow>
          <InfoHide
            info1="Add Stock"
            info2="Please upload a valid CSV with the following format:"
          />
        </MDBRow>
        <MDBRow><p className="infoText">Format for the production environment:</p></MDBRow>

        <MDBRow>
          <MDBTable bordered>
            <MDBTableHead>
              <tr>
                <th>SerialNum</th>
                <th>TerminalType</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              <tr>
                <td>01234567 </td>
                <td>RP10-P1-W</td>
              </tr>
              <tr>
                <td>01233458 </td>
                <td>RP10-P1-W</td>
               
              </tr>
            </MDBTableBody>
          </MDBTable>

        </MDBRow>
        <MDBRow> <p className="infoText">Format for the development environment:</p></MDBRow>
        <MDBRow>
        <MDBTable bordered>
            <MDBTableHead>
              <tr>
                <th>SerialNum</th>
                <th>TerminalType</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              <tr>
                <td>01234567 </td>
                <td>RP10-D1-W</td>
              </tr>
              <tr>
                <td>01233458 </td>
                <td>RP10-D1-W</td>
               
              </tr>
            </MDBTableBody>
          </MDBTable>
        </MDBRow>

        <div>
          {/* Show any errors when the occur */}
          <p>{this.state.error}</p>
          {// File loaded by default is set to false
            //If set to true display to line 128 :
            this.state.fileLoaded ? (
              <div>
                <MDBRow style={{ marginBottom: "10px" }}>
                  <MDBCol>
                    <SCPTable header={header} table={this.state.table} />
                  </MDBCol>
                </MDBRow>

                <MDBRow center style={{ display: "contents" }}>
                  <MDBBtn
                    color="success"
                    type="submit"
                    onClick={() => {
                      this.handleSubmit();
                    }}
                  >
                    Submit
                  </MDBBtn>
                  <MDBBtn
                    color="danger"
                    type="submit"
                    onClick={() => {
                      this.props.history.goBack();
                    }}
                  >
                    Cancel
                  </MDBBtn>
                </MDBRow>
              </div>
            ) : (
              // When fileLoaded set to false display The below.
              <MDBRow>
                <CSVReader
                  cssClass="csv-reader-input"
                  label="Select file with Stock to load "
                  onFileLoaded={this.fileLoaded}
                  onError={this.handleError}
                  parserOptions={papaparseOptions}
                  inputId="Stock"
                  inputStyle={{ color: "red" }}
                />
              </MDBRow>
            )}
          {this.state.isSubmitted ? (
            // <MDBAnimation type="bounceOut" delay="5s">
            <div>
              <h4 style={{ color: "green" }}>
                <MDBIcon icon="thumbs-up" /> Submitted
              </h4>
            </div>
          ) : (
            // {console.log("Submitted", this.state.isSubmitted)}
            // </MDBAnimation>
            <MDBAnimation type="bounceOut" delay="5s">
              <div>
                <p></p>
              </div>
              {console.log("NotSubmitted", this.state.isSubmitted)}
            </MDBAnimation>
          )}
        </div>
      </MDBContainer>
    );
  }
}

export default StockManagementPage;
