import { cdpenv } from "./scp-exports.js";
import awsmobile from "./aws-exports.js";
import Amplify from 'aws-amplify';

Amplify.configure({

  Auth: {
    identityPoolId: awsmobile.aws_cognito_identity_pool_id,
    region: awsmobile.aws_cognito_region,
    userPoolId: awsmobile.aws_user_pools_id,
    userPoolWebClientId: awsmobile.aws_user_pools_web_client_id,
  },
  API: {
    endpoints: [
      {
        name: "TennantAPI",
        endpoint: cdpenv.TennantAPI
      },

    ]
  }
});

export default Amplify