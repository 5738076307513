import { connect } from "react-redux";
import Component from "./App";
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';

const Connected = connect(
    ({ loading }) => ({ loading })
)(Component);

export default (Connected);
