export default {
      home: "/",
      serial: '/serial',
      stock: "/stockManagement",
      fulfill: "/fulfillOrder",
      complete: "/completeOrder",
      org: "/organisations",
      remove: "/removePed",
      transactions: "/transactions",
      stats: "/stats",
      order: "/order"
};
