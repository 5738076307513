
import React from "react";
import {
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBContainer,
  MDBAnimation,
  MDBIcon,
} from "mdbreact";
import CSVReader from "react-csv-reader";
import Amplify from "../../api.js";
import SCPTable from "../SCPTable.js";
import InfoBlock from "../../components/infoBlock";
import InfoHide from "../../components/InfoHide";

const papaparseOptions = {
  header: true,
  skipEmptyLines: true,
};

class OrdersPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileLoaded: false,
      error: "",
      table: [],
      qtyData: [],
      PFdata: [],
      isSubmitted: false,
      data: [],
      qty:"",
    };
    this.fileLoaded = this.fileLoaded.bind(this);
    this.handleError = this.handleError.bind(this);
    this.setError = this.setError.bind(this);
    this.displayData = this.displayData.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.isSubmitted = this.isSubmitted.bind(this);
    this.postOrder = this.postOrder.bind(this);
    this.getQtyOrder = this.getQtyOrder.bind(this);
    this.getFullFilledDetails = this.getFullFilledDetails.bind(this);
    this.getOrder = this.getOrder.bind(this);
  }

  //Sets the state of error
  handleError = () => {
    this.state.error("Invalid File format");
  };

  handleSubmit = () => {
    let payload = [];
    //Goes through each row of the table and pushes the payload.
    for (var i = 0; i < this.state.table.length; i++) {
      payload.push({
        SerialNum: this.state.table[i][0].text,
      });
    }

    this.postOrder();
    
    // this.props.addStock(payload);
    this.setState({ isSubmitted: true });
    // //Goes back to previous page
    this.setState({ fileLoaded: false });
    this.setState({isSubmitted: false})
  };

  async getOrderDetail() {
    const search = this.props.location.search;
    const params = new URLSearchParams(search);
    const ordernum = params.get("order");
    let apiName = "TennantAPI";
    let path = "managementsite/fulfillorder/";
    let myInit = {
      headers: {
        Authorization: `Bearer ${(await Amplify.Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
      queryStringParameters: { start: ordernum, max: 1 },
    };
    console.log("Calling ", apiName);
    console.log("myinit", myInit);
    return await Amplify.API.get(apiName, path, myInit);
  }

  getFullFilledDetails() {
    this.getOrderDetail()
      .then((response) => {
        console.log("PFresponse", response);
        if (response === null) {
          this.setState({ PFdata: [] });
        } else {
          var array = [],
            item = [];
          var i, j;
          for (i = 0; i < response.length; i++) {
            for (j = 0; j < response[i].dispatched.length; j++) {
              item = [
                { text: response[i].dispatched[j].dispatchDate },
                { text: response[i].dispatched[j].qty },
                { text: response[i].dispatched[j].terminalType },
              ];
              array.push(item);
            }
          }
          this.setState({ PFdata: array });
        }
      })
      .catch((error) => {
        console.log("Failed to get data " + error);
      });
  }

  getOrder() {
    this.getOrderDetail()
      .then((response) => {
        console.log("response", response);
        if (response === null) {
          this.setState({ data: [] });
        } else {
          var array = [],
            item = [];
          var i, j;
          for (i = 0; i < response.length; i++) {
            for (j = 0; j < response[i].orderDetail.length; j++) {
              item = [
                { text: response[i].orderDetail[j].TID },
                { text: response[i].orderDetail[j].terminalType },
                { text: response[i].orderDetail[j].status },
                {
                  text: "Serial",
                  label: "Enter Serial",
                  value: this.state.serials,
                },
              ];
              array.push(item);
            }
          }
          this.setState({ data: array });
        }
      })
      .catch((error) => {
        console.log("Failed to get data " + error);
      });
  }

  componentDidMount() {
    this.getQtyOrder();
  }

  getQtyOrder() {
    this.getOrderDetail()
      .then((response) => {
        let totalQty ="";

        if (response === null) {
          this.setState({ qtyData: [] });
        } else {
          totalQty = response[0].order[0].qty;
          var array = [],
            item = [];
          var i, j;
          for (i = 0; i < response.length; i++) {
            for (j = 0; j < response[i].order.length; j++) {
              item = [
                { text: response[i].order[j].qty },
              ];
              array.push(item);
            }
          }
          this.setState({ qtyData: array });
          this.setState({qty: totalQty});
          this.getOrder();
          this.getFullFilledDetails();
        }
      })
      .catch((error) => {
        console.log("Failed to get data " + error);
      });
  }

  async postOrder() {

    let payload = [];
    //Goes through each row of the table and pushes the payload.
    for (var i = 0; i < this.state.table.length; i++) {
      payload.push({
        SerialNum: this.state.table[i][0].text,
      });
    }

    const search = this.props.location.search;
    const params = new URLSearchParams(search);
    const ordernum = params.get("order");
    let apiName = "TennantAPI";
    let path = "managementsite/fulfillorder/" + ordernum;
    let myInit = {
      headers: {
        Authorization: `Bearer ${(await Amplify.Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
      body: payload,
    };
    console.log("Calling ", apiName, payload, ordernum);
    return await Amplify.API.post(apiName, path, myInit)
      .then(() => {
        console.log("refresh");
        this.addedSerials();
      })
      .catch((error) => {
        console.log("Failed to get Terminal Types " + error);
      });
  }

  //Error Message State
  setError = (msg) => {
    this.setState({ error: msg });
  };

  isSubmitted() {}

  fileLoaded = (data) => {
    //Setting state of errors
    this.setState({ error: "" });
    //Check Terminals before file uploaded
    this.props.checkSerials({
      data: data,
      error: this.setError,
      display: this.displayData,
    });

    //Goes through the table array
    let table = [];
    for (var i = 0; i < data.length; i++) {
      table.push([{ text: data[i].SerialNum }]);
    }
    this.setState({ table: table });
    this.setState({ isSubmitted: false });
    console.log(table, "TABLE")
  };

  //Sets state of file loaded to true
  displayData = () => {
    this.setState({ fileLoaded: true });
  };


  render() {
    //Render of valid table headers
    const header = ["SerialNumber"];

    return (
      <MDBContainer>
        <MDBRow>
          <InfoHide
            info1="Assign Stock to an order"
            info2="Please upload a valid CSV with the following format:"
          />
        </MDBRow>
        <MDBRow>
          <InfoBlock
            CardText2="SerialNum"
            CardText3="01234567"
            CardText4="01233457"
          />
          {/* <p>Required Serials for this order{this.state.qtyData[0][0].text}</p> */}
          {console.log("PFData", this.state.PFdata)}
          {console.log("QTYData", this.state.qtyData[0])}
           {this.state.PFdata.length !== 0 ? (
            <MDBCol>
              <p>
                PEDs already dispatched:{" "}
                <strong>{this.state.PFdata[0][1].text}</strong>{" "}
              </p>
              <p>
                Date Dispatched: <strong>{this.state.PFdata[0][0].text}</strong>{" "}
              </p>
              <p>
                Type of PED Dispatches:{" "}
                <strong>{this.state.PFdata[0][2].text}</strong>{" "}
              </p>
              <p>
                Remaining PEDs to FulFill:
                <strong style={{color:"red"}}>{parseInt(parseInt(10, this.state.qtyData[0][0].text) - this.state.PFdata[0][1].text)}</strong>
              </p>
            </MDBCol>
          ) : (
            <p>PEDs to Dispatch: <strong>{this.state.qty}</strong></p>
          )}
        </MDBRow>

        <div>
          {/* Show any errors when the occur */}
          <p>{this.state.error}</p>
          {// File loaded by default is set to false
          //If set to true display to line 128 :
          this.state.fileLoaded ? (
            <div>
              <MDBRow style={{ marginBottom: "10px" }}>
                <MDBCol>
                  <SCPTable header={header} table={this.state.table} />
                </MDBCol>
              </MDBRow>

              <MDBRow center style={{ display: "contents" }}>
                <MDBBtn
                  color="success"
                  type="submit"
                  onClick={() => {
                    this.handleSubmit();
                  }}
                >
                  Submit
                </MDBBtn>
                <MDBBtn
                  color="danger"
                  type="submit"
                  onClick={() => {
                    this.props.history.goBack();
                  }}
                >
                  Cancel
                </MDBBtn>
              </MDBRow>
            </div>
          ) : (
            // When fileLoaded set to false display The below.
            <MDBRow>
              <MDBCol>
                <CSVReader
                  cssClass="csv-reader-input"
                  label="Select file with Stock to load"
                  onFileLoaded={this.fileLoaded}
                  onError={this.handleError}
                  parserOptions={papaparseOptions}
                  inputId="Stock"
                  inputStyle={{ color: "red" }}
                />
              </MDBCol>
            </MDBRow>
          )}
          {this.state.isSubmitted ? (
            // <MDBAnimation type="bounceOut" delay="5s">
            <div>
              <h4 style={{ color: "green" }}>
                <MDBIcon icon="thumbs-up" /> Submitted
              </h4>
            </div>
          ) : (
            // {console.log("Submitted", this.state.isSubmitted)}
            // </MDBAnimation>
            <MDBAnimation type="bounceOut" delay="5s">
              <div>
                <p></p>
              </div>
              {console.log("NotSubmitted", this.state.isSubmitted)}
            </MDBAnimation>
          )}
        </div>
      </MDBContainer>
    );
  }
}

export default OrdersPage;
