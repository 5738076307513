import { MDBNav, MDBNavItem, MDBIcon } from "mdbreact";
import { Component } from "react";
import React from "react";
import './InfoHide.css';

class InfoHide extends Component {

    render() {
        return (

            <MDBNav tabs className="smallInfoBar">
                <MDBIcon icon="info-circle" style={{paddingRight:"7px", marginTop:"3px"}} />
                <MDBNavItem>
                    {this.props.info1}
                </MDBNavItem>
                <MDBNavItem className="info2">
                   {this.props.info2}
                </MDBNavItem>
                <MDBNavItem className="info2">
                    {this.props.info3}
                </MDBNavItem>
            </MDBNav>


        )
    }
}

export default InfoHide;